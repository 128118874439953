// 查看详情>单独下载接口    印章类型
<template>
<div>
     <base-button  class="formBtn" v-bind="$attrs"  @click.native="download" />
</div>
</template>

<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
// import { downFile } from '@/utils/devUtils'
import { certifyApi } from '@/api/companyApi'

export default {
  name: 'downSeal',
  components: { BaseButton },
  props: {},
  computed: {
    file () {
      return this.$attrs
    }
  },
  // 监控data中的数据变化
  watch: {},
  mounted () {

  },
  // 方法集合
  methods: {
    download () {
      certifyApi.getSealImage({ sealAdminId: this.$attrs.keyId.keyId }).then(res => {
        if (res.code === '0') {
          const fileName = '印章'
          const blob = this.dataURItoBlob(res.data.base64) // new Blob([content]);
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName

            // 此写法兼容可火狐浏览器
            document.body.appendChild(link)
            const evt = document.createEvent('MouseEvents')
            evt.initEvent('click', false, false)
            link.dispatchEvent(evt)
            document.body.removeChild(link)
          }
        } else {
          this.warning('暂无资源')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // base64转化为blob
    dataURItoBlob (base64Data) {
      base64Data = 'data:image/png;base64,' + base64Data
      const parts = base64Data.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    }
  }
}
</script>
<style lang='scss' scoped>
.formBtn{
  padding: 0;
  text-align: center;
  width: 30px;
  height: 30px;
  display: block;
  border-radius:50% ;
  /deep/.iconfont {
    margin: 0 !important;
  }
}
</style>
